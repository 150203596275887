import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react'

import {
    MinusIcon,
    AddIcon
} from '@chakra-ui/icons'

const AudioComponent = ({ data, title }) => {
    return (

        <div className='py-20'>

            <h1 className=' font-semibold text-[34px] max-w-[1085px] mx-auto mb-5'> {title} - please click to expand</h1>

            <Accordion allowToggle>
                {data.map((item) => (
                    <div id={item.id} key={item.name} className='max-w-[1085px] mx-auto '>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        {item.name}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <p>{item.description}</p>
                                <audio className='my-4' src={item.href} controls />
                                {item.href2 && (
                                    <>
                                        <p>{item.description2}</p>
                                        <audio className='my-4' src={item.href2} controls />
                                    </>
                                )}
                            </AccordionPanel>

                        </AccordionItem>
                    </div>
                ))}
            </Accordion>



        </div>
    )
}

export default AudioComponent