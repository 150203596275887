import React from 'react'
import DropDownBtn from './dropDownBtn'
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom'

// import {
//     ArrowPathIcon,
//     ChartPieIcon,
//     CursorArrowRaysIcon,
//     FingerPrintIcon,
//     SquaresPlusIcon,
// } from '@heroicons/react/24/outline'

import video from "../data/video.json"
import photography from "../data/photography.json"
import audio from "../data/audio.json"
import digital from "../data/digital.json"
import print from "../data/print.json"

import MMGY_logo from "../images/MMGY_red_white letters.png"

const NavBar = () => {
    const location = useLocation();

    // Extract any necessary information from the URL (e.g., pathname, search, or params)
    const pathname = location.pathname;
    const search = location.search;

    // Conditionally render or exclude navbar
    const renderHeader = pathname.includes("Previews");
    return (
        <>
            {renderHeader
                ? <div className='h-20 w-full bg-[#414042] sticky top-0 z-10 text-white'>

                    <div className='flex py-2 mx-auto items-center justify-between max-w-[1400px]'>
                        <h1 className=' text-xl font-bold'>Work Showcase</h1>


                        {/* <div className='flex gap-10 '>
                            <DropDownBtn data={video} dataType="Video" />
                            <DropDownBtn data={photography} dataType="Photography" />
                            <DropDownBtn data={audio} dataType="Audio" />
                            <DropDownBtn data={digital} dataType="Digital" />
                            <DropDownBtn data={print} dataType="Print" />
                        </div> */}


                        <div className='w-[150px]'><img alt='' className='h-16 ml-auto' src={MMGY_logo}></img></div>

                    </div>

                </div>
                : <div className='h-20 w-full bg-[#414042] sticky top-0 z-10 text-white'>

                    <div className='flex py-2 mx-auto items-center justify-between max-w-[1400px]'>
                        <Link to='/'>
                            <h1 className=' text-xl font-bold'>Work Showcase</h1>
                        </Link>

                        <div className='flex gap-10 '>
                            <DropDownBtn data={video} dataType="Video" />
                            <DropDownBtn data={photography} dataType="Photography" />
                            <DropDownBtn data={audio} dataType="Audio" />
                            <DropDownBtn data={digital} dataType="Digital" />
                            <DropDownBtn data={print} dataType="Print" />
                        </div>


                        <div className='w-[150px]'><img alt='' className='h-16 ml-auto' src={MMGY_logo}></img></div>

                    </div>

                </div>}
        </>
    )
}

export default NavBar