import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

const PhotoComponent = ({ data, title }) => {
    const renderAccordionSection = (sectionData, sectionTitle) => {
        return (
            <div className='max-w-[1085px] mx-auto '>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left'>
                                {sectionData[0].name}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <p>{sectionData[0].description}</p>
                        <div className='rounded-xl bg-[#0000000a] text-sm leading-6 shadow-lg my-5 h-[630px] w-full overflow-hidden'>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                    type: 'fraction',
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >
                                {sectionData.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <img alt='' src={item.href} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </div>
        );
    };

    return (
        <div className='py-20'>
            <h1 className=' font-semibold text-[34px] max-w-[1085px] mx-auto mb-5'>{title} - please click to expand</h1>
            <Accordion allowToggle>
                {renderAccordionSection(data.events, data.events[0].name)}
            </Accordion>
            <Accordion allowToggle>
                {renderAccordionSection(data.newsletters, data.newsletters[0].name)}
            </Accordion>
        </div>
    );
}

export default PhotoComponent;
