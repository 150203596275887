import React from 'react';
import './App.css'
import NavBar from './components/navBar';
import { Routes, Route, useLocation } from 'react-router-dom';

import Video from './pages/video'
import Home from './pages/home';
import Photography from './pages/photography';
import Audio from './pages/audio';
import Digital from './pages/digital';
import Print from './pages/print';

import MobileBuild from './pages/mobileBuid'



import VideoContent from "./data/videoContent.json"
import DigitalContent from "./data/digitalContent.json"
import PhotographyContent from "./data/photographyContent.json"
import AudioContent from "./data/audioContent.json"
import PrintContent from "./data/printContent.json"

// client previews
import PreviewContent from "./data/clientPreviews.json"
import BrandUsaPreview from "./data/clientPreviewsBrandUsa.json"
import OntarioPreview from "./data/clientPreviewsOntario.json"

import { ChakraProvider } from '@chakra-ui/react'
// import VideoComponent from './components/videoComponent';
import DigitalComponent from './components/digitalComponent';
import PreviewPage from './components/previewPage';
import PhotoComponent from './components/photoComponent';

import AudioComponent from './components/audioComponent';




const App = () => {
  const location = useLocation();
  return (
    <div className='app'>
      <ChakraProvider>
        <div className='hidden min-[875px]:block'>
          <NavBar />
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />

            {/* ------------------------------- Video Page ------------------------------- */}
            <Route path='video' element={<Video />} />

            <Route path='video/tvc-commercial' element={<DigitalComponent title="TVC Commercial" data={VideoContent.tvc} />} />
            <Route path='video/celebrity-lead' element={<DigitalComponent title="Celebrity lead" data={VideoContent.celebLead} />} />
            <Route path='video/local-lead' element={<DigitalComponent title="Locals lead" data={VideoContent.localsLead} />} />
            <Route path='video/events' element={<DigitalComponent title="Events, Conferences & Ceremonies" data={VideoContent.events} />} />
            <Route path='video/product' element={<DigitalComponent title="Product" data={VideoContent.product} />} />
            <Route path='video/social-media' element={<DigitalComponent title="Social Media" data={VideoContent.socialMedia} />} />
            <Route path='video/campaign-wrap' element={<DigitalComponent title="Campaign Wrap" data={VideoContent.campaignWrap} />} />

            {/* ------------------------------- Photography Page ------------------------------- */}
            <Route path='photography' element={<Photography />} />

            <Route path='photography/events' element={<PhotoComponent title="Events" data={PhotographyContent} />} />


            {/* ------------------------------- Audio Page ------------------------------- */}
            <Route path='audio' element={<Audio />} />
            <Route path='audio/radio-spotify' element={<AudioComponent title="Radio & Spotify" data={AudioContent.radioSpotify} />} />
            {/* ------------------------------- Digital Page ------------------------------- */}
            <Route path='digital' element={<Digital />} />

            <Route path='digital/websites' element={<DigitalComponent title="Websites" data={DigitalContent.websites} />} />
            <Route path='digital/newsletters' element={<DigitalComponent title="Newsletters" data={DigitalContent.newsletters} />} />
            <Route path='digital/content-box' element={<DigitalComponent title="Content Box" data={DigitalContent.contentBox} />} />
            <Route path='digital/banner-ads' element={<DigitalComponent title="Banner Ads" data={DigitalContent.bannerAds} reloads="yes" />} />

            {/* ------------------------------- Print Page ------------------------------- */}
            <Route path='print' element={<Print />} />
            <Route path='print/full-page' element={<DigitalComponent title="Full Page" data={PrintContent.fullPage} />} />
            <Route path='print/booklet' element={<DigitalComponent title="Booklet" data={PrintContent.booklet} />} />

            {/* ------------------------------- Previews ------------------------------- */}

            <Route path='Previews/Myrtle-Beach-Southwest' element={<DigitalComponent title="Myrtle Beach | Southwest" data={PreviewContent.myrtleBeach} reloads="yes" />} />

            {/* Brand USA */}
            <Route path='Previews/BrandUsa-Lux/Abercrombie&Kent' element={<DigitalComponent title="Brand USA - Luxury | Abercrombie & Kent" data={BrandUsaPreview.ak} reloads="yes" />} />

            <Route path='Previews/BrandUsa-Lux/Audley' element={<DigitalComponent title="Brand USA - Luxury | Audley" data={BrandUsaPreview.audley} reloads="yes" />} />

            <Route path='Previews/BrandUsa-Lux/Wexas' element={<DigitalComponent title="Brand USA - Luxury | Wexas" data={BrandUsaPreview.wexas} reloads="yes" />} />

            {/* Ontario */}
            <Route path='Previews/Ontario/Main-plan/Trailfinders' element={<PreviewPage data={OntarioPreview.trails} reloads="yes" />} />

            <Route path='Previews/Ontario/Main-plan/Canadian-Affair' element={<PreviewPage data={OntarioPreview.affair} reloads="yes" />} />

            <Route path='Previews/Ontario/Main-plan/My-Canada-Trips' element={<PreviewPage data={OntarioPreview.trips} reloads="yes" />} />


          </Routes>
        </div>

        <div className='block min-[875px]:hidden'>
          <MobileBuild />
        </div>
      </ChakraProvider>
    </div>
  );
};

export default App;
