import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react'

import {
    MinusIcon,
    AddIcon
} from '@chakra-ui/icons'

const DigitalComponent = ({ data, title, reloads }) => {
    
    const reloadYes = !!reloads;
        

    return (

        <div className='py-20'>

            <h1 className=' font-semibold text-[34px] max-w-[1085px] mx-auto mb-5'> {title} <span className='block text-[18px] opacity-40 mt-2'>Please click to expand</span></h1>

            <Accordion allowToggle>
                {data.map((item) => (
                    <div id={item.id} key={item.name} className='max-w-[1085px] mx-auto  '>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        {item.name}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <p>{item.description}</p>
                                <iframe
                                    className=' bg-white text-sm leading-6 shadow-lg my-5 ring-1 ring-gray-900/5'
                                    src={item.href}
                                    title="Media"
                                    style={{ height: item.height, width: item.width }}
                                    loading="lazy"
                                    ref={(iframeRef) => (item.iframeRef = iframeRef)} // Store a reference to the iframe
                                ></iframe>

                                {reloadYes &&
                                <button className='rounded-lg px-4 py-2 bg-[#f04c2fff] text-white hover:bg-[#a32c17] duration-300'
                                    onClick={() => {
                                        if (item.iframeRef) {
                                            item.iframeRef.src = item.href; // Reload the specific iframe associated with the item
                                        }
                                    }}
                                >
                                    Reload Ad
                                </button>}

                                {item.href2 && (
                                    <iframe
                                        className='rounded-xl bg-white text-sm leading-6 shadow-lg my-5'
                                        src={item.href2}
                                        title="Media"
                                        style={{ height: item.height, width: item.width }}
                                        loading="lazy"
                                    ></iframe>
                                )}
                                {item.href3 && (
                                    <iframe
                                        className='rounded-xl bg-white text-sm leading-6 shadow-lg my-5'
                                        src={item.href3}
                                        title="Media"
                                        style={{ height: item.height, width: item.width }}
                                        loading="lazy"
                                    ></iframe>
                                )}
                                {item.href4 && (
                                    <iframe
                                        className='rounded-xl bg-white text-sm leading-6 shadow-lg my-5'
                                        src={item.href4}
                                        title="Media"
                                        style={{ height: item.height, width: item.width }}
                                        loading="lazy"
                                    ></iframe>
                                )}
                            </AccordionPanel>

                        </AccordionItem>
                    </div>
                ))
                }
            </Accordion >



        </div >
    )
}

export default DigitalComponent