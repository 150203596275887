import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const DropDownBtn = ({ data, dataType }) => {
    // console.log(data);
    const [isShowing, setIsShowing] = useState(false)

    return (
        <Popover id='ultBtn' className="relative"
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}>
            <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold duration-500 leading-6">
                <span
                >{dataType}</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                show={isShowing}
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
            >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 dropDownMenuChange">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4">
                            {data.map((item) => (
                                <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-[#f04c2f] " onClick={() => setIsShowing(false)}
                                >
                                    {/* <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
    </div> */}
                                    <div>
                                        {item.name.includes("All") ? (
                                            // Render content for items with "ALL" in the name
                                            <>
                                                <Link to={item.href} className=" text-base font-extrabold text-black hover:text-white">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                                <div className="mt-1 text-gray-600">{item.description}</div>
                                            </>
                                        ) : (
                                            // Render content for other items
                                            <>
                                                <Link to={item.href} className="font-semibold text-gray-900 hover:text-white">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                                <div className="mt-1 text-gray-600">{item.description}</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                        {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                            {callsToAction.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                                >
                                    <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                    {item.name}
                                </a>
                            ))}
                        </div> */}
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

export default DropDownBtn