import React from 'react'
import spanner from '../images/211751_gear_icon.png'

const Home = () => {
    return (
        <div className='pt-32 text-center h-[300px] text-[50px]'>
            Home
            <p className='text-[15px] mt-4'>Page under construction please come back</p>
            <img alt='' className='spinner h-[60px] mt-5  mx-auto' src={spanner}></img>
        </div>
    )
}

export default Home