import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <Router> */}
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      {/* </Router> */}
    </BrowserRouter>
  </React.StrictMode>
);


