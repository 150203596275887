import React from 'react'
import spanner from '../images/211751_gear_icon.png'

import VideoContent from "../data/videoContent.json"
import VideoContentAll from '../components/videoContentAll'
// import VideoComponent from '../components/videoComponent'

const Video = () => {
    const transformedData = [];

    // Iterate over each key in the JSON data (e.g., "tvc", "celebLead", etc.)
    for (const key in VideoContent) {
        if (VideoContent.hasOwnProperty(key)) {
            // Iterate over each item in the current array
            for (const item of VideoContent[key]) {
                // Extract the relevant properties and create a new object
                const newItem = {
                    ...item,
                    id: item.id // Use "id" if available, or fallback to "name"
                };

                // Push the new object into the transformedData array
                transformedData.push(newItem);
            }
        }
    }

    // console.log(transformedData);


    return (
        <div className='pt-32 text-center h-[300px] text-[50px]'>
            All Video
            <p className='text-[15px] mt-4'>Page under construction please come back</p>
            <img alt='' className='spinner h-[60px] mt-5  mx-auto' src={spanner}></img>
        </div>
        // <div className='py-20'>

        //     <VideoContentAll data={transformedData} />


        // </div>

    )
}

export default Video