import React from 'react'
import spanner from '../images/211751_gear_icon.png'
import MMGY_Logo from '../images/../images/MMGY_red_white letters 2.png'

const Mobile = () => {
    return (
        <div className='pt-[20vh] text-center h-full text-[50px] flex flex-col px-20'>
            <img alt='' src={MMGY_Logo} className='w-32 mx-auto mb-5' ></img>
            <p className='text-[17px] mt-4'>Mobile is not ready just yet, please check later !</p>
            <img alt='' className='spinner h-[60px] mt-8  mx-auto' src={spanner}></img>
        </div>
    )
}

export default Mobile