import React from 'react'
import { NavLink } from 'react-router-dom'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react'



import {
    MinusIcon,
    AddIcon
} from '@chakra-ui/icons'

const PreviewPage = ({ data, title, reloads }) => {

    const reloadYes = !!reloads;

    const partnerStyle = 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2 hover:ring-[#f04c2f] hover:opacity-50 duration-500 cursor-pointer '


    return (

        <div className='py-20'>

            <h1 className='font-semibold text-[48px] max-w-[1085px] mx-auto mb-5'>
                Destination Ontario
                <div id='prev-title' className='text-[23px]'>
                    <NavLink to=''> Main plan </NavLink>
                    <span> | Toronto | </span>
                    <span> Niagara Park </span>
                </div>
            </h1>

            <div id='prev-links' className='max-w-[1085px] mx-auto mb-5 flex justify-end'>
                <NavLink to='/Previews/Ontario/Main-plan/Trailfinders' className={partnerStyle}>Trailfinders </NavLink>
                <NavLink to='/Previews/Ontario/Main-plan/Canadian-Affair' className={partnerStyle}>Canadian Affair </NavLink>
                <NavLink to='/Previews/Ontario/Main-plan/My-Canada-Trips' className={partnerStyle}>My Canada Trips</NavLink>
            </div>
            <Accordion allowToggle>
                {data.map((item) => (
                    <div id={item.id} key={item.name} className='max-w-[1085px] mx-auto  '>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        {item.name}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <p>{item.description}</p>
                                <iframe
                                    className=' bg-white text-sm leading-6 shadow-lg my-5 ring-1 ring-gray-900/5'
                                    src={item.href}
                                    title="Media"
                                    style={{ height: item.height, width: item.width }}
                                    loading="lazy"
                                    ref={(iframeRef) => (item.iframeRef = iframeRef)} // Store a reference to the iframe
                                ></iframe>

                                {reloadYes &&
                                    <button className='rounded-lg px-4 py-2 bg-[#f04c2fff] text-white hover:bg-[#a32c17] duration-300'
                                        onClick={() => {
                                            if (item.iframeRef) {
                                                item.iframeRef.src = item.href; // Reload the specific iframe associated with the item
                                            }
                                        }}
                                    >
                                        Reload Ad
                                    </button>}

                                {item.href2 && (
                                    <iframe
                                        className='rounded-xl bg-white text-sm leading-6 shadow-lg my-5'
                                        src={item.href2}
                                        title="Media"
                                        style={{ height: item.height, width: item.width }}
                                        loading="lazy"
                                    ></iframe>
                                )}
                                {item.href3 && (
                                    <iframe
                                        className='rounded-xl bg-white text-sm leading-6 shadow-lg my-5'
                                        src={item.href3}
                                        title="Media"
                                        style={{ height: item.height, width: item.width }}
                                        loading="lazy"
                                    ></iframe>
                                )}
                                {item.href4 && (
                                    <iframe
                                        className='rounded-xl bg-white text-sm leading-6 shadow-lg my-5'
                                        src={item.href4}
                                        title="Media"
                                        style={{ height: item.height, width: item.width }}
                                        loading="lazy"
                                    ></iframe>
                                )}
                            </AccordionPanel>

                        </AccordionItem>
                    </div>
                ))
                }
            </Accordion >

            <span className='block text-[18px] opacity-40 mt-5 max-w-[1085px] mx-auto mb-5 text-center'>Please click to expand</span>

        </div >
    )
}

export default PreviewPage